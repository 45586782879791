export const brandHostPaths = {
  nrma: {
    local: 'http://localhost:5002/nrma',
    development: 'https://dev.k8s.devlabs/consent/nrma',
    test: 'https://tst.k8s.devlabs/consent/nrma',
    bat: 'https://bat.k8s.devlabs/consent/nrma',
    sys: 'https://sys.k8s.devlabs/consent/nrma',
    fe136: 'https://devlabs-fe-entry-1-k8s.env-10-100-136.iagcloud/consent/nrma',
    fe164: 'https://devlabs-fe-entry-1-k8s.env-10-100-164.iagcloud/consent/nrma',
    fe180: 'https://devlabs-fe-entry-1-k8s.env-10-100-180.iagcloud/consent/nrma',
    fe186: 'https://devlabs-fe-entry-1-k8s.env-10-100-186.iagcloud/consent/nrma',
    // note: because the brand is in the domain we don't have it in path
    fe224: 'https://connect.ve224.nrma.com.au/consent',
    fe249: 'https://devlabs-fe-entry-1-k8s.env-10-100-249.iagcloud/consent/nrma',
    staging: 'https://stg.secure.nrma.com.au/consent',
    production: 'https://secure.nrma.com.au/consent',
  },
  nrmasilver: {
    local: 'http://localhost:5002/nrmasilver',
    development: 'https://dev.k8s.devlabs/consent/nrmasilver',
    test: 'https://tst.k8s.devlabs/consent/nrmasilver',
    bat: 'https://bat.k8s.devlabs/consent/nrmasilver',
    sys: 'https://sys.k8s.devlabs/consent/nrmasilver',
    fe136: 'https://devlabs-fe-entry-1-k8s.env-10-100-136.iagcloud/consent/nrmasilver',
    fe164: 'https://devlabs-fe-entry-1-k8s.env-10-100-164.iagcloud/consent/nrmasilver',
    fe180: 'https://devlabs-fe-entry-1-k8s.env-10-100-180.iagcloud/consent/nrmasilver',
    fe186: 'https://devlabs-fe-entry-1-k8s.env-10-100-186.iagcloud/consent/nrmasilver',
    fe224: 'https://connect.ve224.nrma.com.au/consent/nrmasilver',
    fe249: 'https://devlabs-fe-entry-1-k8s.env-10-100-249.iagcloud/consent/nrmasilver',
    staging: 'https://stg.connect.nrma.com.au/consent',
    production: 'https://connect.nrma.com.au/consent',
  },
  sharecover: {
    local: 'http://localhost:5002/sharecover',
    development: 'https://secure.sharecover.dev/consent', // note: sharecover's dev, our tst
    test: 'https://secure.sharecover.xyz/consent', // note: sharecover's tst, our stg
    bat: 'https://bat.k8s.devlabs/consent/sharecover',
    sys: 'https://sys.k8s.devlabs/consent/sharecover',
    fe136: 'https://devlabs-fe-entry-1-k8s.env-10-100-136.iagcloud/consent/sharecover',
    fe164: 'https://devlabs-fe-entry-1-k8s.env-10-100-164.iagcloud/consent/sharecover',
    fe180: 'https://devlabs-fe-entry-1-k8s.env-10-100-180.iagcloud/consent/sharecover',
    fe186: 'https://devlabs-fe-entry-1-k8s.env-10-100-186.iagcloud/consent/sharecover',
    fe224: 'https://devlabs-fe-entry-1-k8s.env-10-100-224.iagcloud/consent/sharecover',
    fe249: 'https://devlabs-fe-entry-1-k8s.env-10-100-249.iagcloud/consent/sharecover',
    staging: 'https://devlabs-staging.auiag.corp/consent/sharecover', // currently unused, see above
    production: 'https://secure.sharecover.com/consent',
  },
  // mock data only
  monochroma: {
    local: 'http://localhost:5002/monochroma',
    development: 'https://dev.k8s.devlabs/consent/monochroma',
    test: 'https://tst.k8s.devlabs/consent/monochroma',
    bat: 'https://bat.k8s.devlabs/consent/monochroma',
    sys: 'https://sys.k8s.devlabs/consent/monochroma',
    fe136: 'https://devlabs-fe-entry-1-k8s.env-10-100-136.iagcloud/consent/monochroma',
    fe164: 'https://devlabs-fe-entry-1-k8s.env-10-100-164.iagcloud/consent/monochroma',
    fe180: 'https://devlabs-fe-entry-1-k8s.env-10-100-180.iagcloud/consent/monochroma',
    fe186: 'https://devlabs-fe-entry-1-k8s.env-10-100-186.iagcloud/consent/monochroma',
    fe224: 'https://devlabs-fe-entry-1-k8s.env-10-100-224.iagcloud/consent/monochroma',
    fe249: 'https://devlabs-fe-entry-1-k8s.env-10-100-249.iagcloud/consent/monochroma',
    staging: 'https://devlabs-staging.auiag.corp/consent/monochroma',
    production: 'https://devlabs-staging.auiag.corp/consent/monochroma',
  },
  // these brands will likely not use the web embed, but the entries are necessary for the admin portal design preview
  driva: {
    local: 'http://localhost:5002/nrma',
    development: 'https://dev.k8s.devlabs/consent/driva',
    test: 'https://tst.k8s.devlabs/consent/driva',
    staging: 'https://devlabs-staging.auiag.corp/consent/driva',
    production: 'https://devlabs-production.auiag.corp/consent/driva',
  },
  anz: {
    local: 'http://localhost:5002/nrma',
    development: 'https://dev.k8s.devlabs/consent/anz',
    test: 'https://tst.k8s.devlabs/consent/anz',
    staging: 'https://devlabs-staging.auiag.corp/consent/anz',
    production: 'https://devlabs-production.auiag.corp/consent/anz',
  },
  bnz: {
    local: 'http://localhost:5002/bnz',
    development: 'https://dev.k8s.devlabs/consent/bnz',
    test: 'https://tst.k8s.devlabs/consent/bnz',
    staging: 'https://devlabs-staging.auiag.corp/consent/bnz',
    production: 'https://devlabs-production.auiag.corp/consent/bnz',
  },
};

export const paths = {
  privacy: '/privacy',
  termsOfUse: '/terms-of-use',
  marketing: '/comms',
  pushNotification: '/push-notifications',
  edocs: '/edocs',
  cookie: '/cookies',
  dataSharing: '/data-sharing',
  policy: '/policy',
  dataUsage: '/data-usage',
  dataCollection: '/data-collection',
  notifications: '/notifications',
  dynamicFlow: '/flow',
  // backward-compat with consent v1
  communications: '/communications',
};

export const embedQueryParams = {
  webEmbed: {
    isWebEmbed: 'true',
  },
  designPreviewEmbed: {
    isDesignPreviewEmbed: 'true',
  },
};
