/*
  Notes
  -----

  - For a quick overview of our approach to embedding content inside of React, see:
    https://reactjs.org/docs/integrating-with-other-libraries.html#how-to-approach-the-problem
  - Always check the build size of the embed script after changes. If the build size of the JS file
    blows out, it's likely because one of the files we're importing below has added its own additional
    imports. If so then extract the bits we need into their own file and import that instead.

*/

import { connectToChild } from 'penpal';
import { brandHostPaths, paths, embedQueryParams } from './iag-ciam-embeds-constants-v1';
import { serialiseQueryParams } from '../utils/queryParams';
import { EmbedMethods, EmbedOptions } from '../components/pages/ConsentV2/types';

(function () {
  /**
   * Ensures that the core embedding options provided to an embedding method are valid. If
   * not, an error will be thrown.
   *
   * @param {string} methodName
   * @param {EmbedOptions} options
   */
  const validateCoreEmbedOptions = (methodName: string, options: EmbedOptions): boolean => {
    const { name, flowSteps, domElementId, environment, brand, isDesignPreviewMode = false } = options;
    const allowedNames = Object.keys(paths);

    if (!name || !allowedNames.includes(name)) {
      console.error(
        new Error(
          `${methodName}: missing or invalid 'name' value of '${name}' provided; cannot continue. Accepted names: ${allowedNames.join(
            ', ',
          )}`,
        ),
      );
      return false;
    }

    if (name === 'dynamicFlow' && !flowSteps) {
      console.error(
        new Error(
          `${methodName}: missing or invalid 'flowSteps' of '${flowSteps}' provided for 'name' of '${name}'; cannot continue.`,
        ),
      );
      return false;
    }

    if (!domElementId || typeof domElementId !== 'string') {
      console.error(
        new Error(`${methodName}: missing or invalid 'domElementId' of '${domElementId}' provided; cannot continue.`),
      );
      return false;
    }

    const brandsValid = Object.keys(brandHostPaths);
    if (!brand || !brandsValid.includes(brand)) {
      console.error(
        new Error(
          `${methodName}: missing or invalid 'brand' of '${brand}' provided; cannot continue. cannot continue. Accepted brands: ${brandsValid.join(
            ', ',
          )}`,
        ),
      );
      return false;
    }

    const environmentsValid = Object.keys(brandHostPaths[brand]);
    if (!environment || !environmentsValid.includes(environment)) {
      console.error(
        new Error(
          `${methodName}: missing or invalid 'environment' value of '${environment}' provided for brand '${brand}'; cannot continue. Accepted environments: ${environmentsValid.join(
            ', ',
          )}`,
        ),
      );
      return false;
    }

    const root = document.querySelector(`#${domElementId}`);
    if (!root) {
      console.error(
        new Error(
          `${methodName}: no current DOM node with ID for provided 'domElementId' of '${domElementId}' found; cannot continue.`,
        ),
      );
      return false;
    }

    if (typeof isDesignPreviewMode !== 'undefined' && typeof isDesignPreviewMode !== 'boolean') {
      console.error(
        new Error(
          `${methodName}: invalid 'isDesignPreviewMode' of '${isDesignPreviewMode}' provided; cannot continue.`,
        ),
      );
      return false;
    }

    return true;
  };

  /**
   * Generates a predictable iframe id from the domElementId passed in.
   *
   * @param {string} domElementId
   * @returns {string}
   */
  const getIframeId = (domElementId: string): string => {
    return `${domElementId}-iframe`;
  };

  /**
   * Applies or re-applies styling to the iframe from within the parent page. Note that
   * anything _within_ the iframe must be styled within the embedded page.
   *
   * @param {string} domElementId
   */
  const updateIframeStyle = (domElementId: string): void => {
    const iframeId = getIframeId(domElementId);
    let el = document.querySelector(`#${domElementId} > style`);
    if (!el) {
      el = document.createElement('style');
      document.querySelector(`#${domElementId}`)?.appendChild(el);
    }
    let css = '';
    css += `
      #${iframeId} {
        width: 100%;
      }
    `;
    el.innerHTML = css;
  };

  /**
   * Creates the iframe and initialises the communication protocols between the parent page
   * and the internal page within the iframe.
   *
   * @param {EmbedOptions} options
   * @returns {any} The Penpal connection that can be used to communicate with the iframe.
   */
  const initIframe = (options: EmbedOptions): any => {
    const { brand, environment, domElementId } = options;
    updateIframeStyle(domElementId);
    // See https://github.com/Aaronius/penpal/tree/3.x#usage
    const iframe = document.createElement('iframe');
    iframe.id = getIframeId(domElementId);
    const embedQueryParamsUsed = {
      ...embedQueryParams.webEmbed,
      ...(options.isDesignPreviewMode ? embedQueryParams.designPreviewEmbed : {}),
      ...(options.name === 'dynamicFlow' && options.flowSteps ? { steps: options.flowSteps } : {}),
    };
    // Penpal 5 change
    iframe.src = `${brandHostPaths[brand][environment]}${paths[options.name]}${serialiseQueryParams(
      embedQueryParamsUsed,
      { arrayFormat: 'comma' },
    )}`;
    document.getElementById(domElementId)?.appendChild(iframe);
    const connection = connectToChild({
      // appendTo: document.getElementById(domElementId) || undefined,
      iframe,
      // methods that we're making available to the iframe's content.
      methods: {
        // method to allow the iframe content to resize the iframe itself
        setIframeAndContainerHeight(px: number) {
          const el = document.querySelector(`#${getIframeId(domElementId)}`) as HTMLElement;
          if (el) el.style.height = `${px}px`;
        },
      },
    });

    // todo: typing on the return type
    return connection;
  };

  /**
   * Initialises the embed and returns methods to communicate with the content inside.
   *
   * @param {EmbedOptions} options
   * @returns {boolean}
   */
  const embed = (options: EmbedOptions): EmbedMethods | false => {
    const methodName = 'iagCiamEmbeds.embed()';
    if (!validateCoreEmbedOptions(methodName, options)) return false;
    const connection = initIframe(options);
    // return an object containing wrapped versions of the methods that we want to let the parent page's
    // consumer code call directly. These methods are provided by the content in the iframe and we can
    // expose just the ones we want public.
    const methods: EmbedMethods = {};
    methods.attemptToSubmitCurrentPage = () => {
      return new Promise((resolve) => {
        connection.promise.then((child) => {
          resolve(child.attemptToSubmitCurrentPage());
        });
      });
    };
    if (options.isDesignPreviewMode) {
      methods.updateDesignPreviewConsent = (consent, designPreviewOptions = {}) => {
        return new Promise((resolve) => {
          connection.promise.then((child) => {
            resolve(child.updateDesignPreviewConsent(consent, designPreviewOptions));
          });
        });
      };
    }
    return methods;
  };

  // todo: get iagCiamEmbeds on the global
  window.iagCiamEmbeds = {
    embed,
  };
})();

export {};
