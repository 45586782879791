import { stringify as qsStringify, IParseOptions, parse as queryStringParse, IStringifyOptions } from 'qs';

/**
 * Converts an object into a query string. For the following input (note the unicode characters):
 *
 *   { name: "Kïm", age: 5, categories: ["one", "twö"] }
 *
 * this will be returned:
 *
 *   ?name=K%C3%AFm&age=5&categories[]=one&categories[]=tw%C3%B6
 *
 * This method is a wrapper around the `qs.stringify()` method with defaults adjusted.
 *
 * @param {Record<string, any>} queryParamsObj
 * @param {Record<string, any>} [qsStringifyOptions] An options object to overwrite defaults. See the list of
 *        stringify options at https://www.npmjs.com/package/qs .
 * @returns {string}
 */
export const serialiseQueryParams = (
  queryParamsObj: Record<string, any>,
  qsOptions: IStringifyOptions = {}
): string => {
  return qsStringify(queryParamsObj, {
    encodeValuesOnly: true,
    arrayFormat: 'brackets',
    addQueryPrefix: true,
    ...qsOptions,
  });
};

/**
 * Grabs a query parameter from a URL.
 *
 * Defaults to searching `window.location.href` if `url` is not specified.
 *
 * This method is a wrapper around the `qs.parse()` method that automaticaly strips leading URL components
 * and ensures a consistent return type.
 *
 * @param {*} param The query parameter to search seek.
 * @returns The full query param including its value if found, else undefined.
 */
export const getQueryParamFromUrl = (
  param: string,
  url: string = window.location.href,
  qsOptions: IParseOptions = {}
): string | string[] | undefined => {
  let r: string | string[] | undefined;
  try {
    // qs only handles query params so remove any leading part
    r = queryStringParse(url.replace(/^.*?\?/, ''), qsOptions)[param] as string | string[] | undefined;
  } catch (e) {
    console.error('getQueryParamFromUrl(): cannot parse provided URL; returning undefined.');
  }
  return r;
};
